<script>
  import VideoJsPlayer from '@/components/courses/course-details/video-js-player/VideoJsPlayer.vue';
  import AudioPlayer from '@/components/courses/course-details/audio-player/AudioPlayer.vue';
  import AuthorThumbnail from '@/components/courses/course-details/author-thumbnail/AuthorThumbnail.vue';
  import { mapGetters, mapActions } from 'vuex';
  import getThumbnail from '@/utils/getThumbnail.js';

  export default {
    name: 'course-details-component',
    data() {
      return {
        thumbnailUrl: null,
        areAuthorsFetched: false,
      };
    },
    props: {
      course: {
        type: Object,
        required: true,
      },
    },
    components: {
      AudioPlayer,
      VideoJsPlayer,
      AuthorThumbnail,
    },
    computed: {
      ...mapGetters('AuthorsModule', ['authors']),
    },
    async created() {
      await this.fetchAllAuthors();
      this.$nextTick(() => (this.areAuthorsFetched = true));
      await this.setThumbnailUrl();
    },
    methods: {
      ...mapActions('AuthorsModule', { fetchAllAuthors: 'fetchAll' }),
      async setThumbnailUrl() {
        try {
          this.thumbnailUrl = await getThumbnail({
            url: this.course.coverUrl,
            folder: 'CoursesCovers',
          });
        } catch {
          this.thumbnailUrl = this.course.coverUrl;
        }
      },
    },
  };
</script>
